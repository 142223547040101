.file-search {
  position: relative;
  width: 100%;
  height: calc(100vh - 57px);
}

.filter-panel {
  position: fixed !important;
  top: calc(57px + 1rem) !important;
  left: 120px !important;
  width: 300px !important;
  bottom: 80px !important;
  background: white !important;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000 !important;
  padding: 20px !important;
  transform: translateX(-100%) !important;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
  overflow-y: auto !important;
}

.filter-panel.open {
  transform: translateX(0) !important;
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0s linear;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.file-search-content {
  margin-left: 0px;
  padding: 24px;
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: column;
}

.search-sidebar {
  width: 300px;
  border-right: 1px solid #eee;
  padding: 20px;
  background: #f8f9fa;
  height: 100%;
  overflow-y: auto;
  transition: transform 0.3s ease;
  position: fixed;
  left: 80px;
  top: 0;
  bottom: 0;
  transform: translateX(-100%);
}

.search-sidebar.open {
  transform: translateX(0);
}

.search-filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-section label {
  font-weight: 500;
  color: #333;
}

.filter-section input,
.filter-section select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-results {
  flex: 1;
  padding: 32px;
  overflow-y: auto;
  background: white;
}

.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.filter-tag {
  background: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.filter-tag button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0 4px;
}

.results-count {
  margin-bottom: 20px;
  font-weight: 500;
}

.file-item {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.file-details {
  flex: 1;
}

.file-details h4 {
  margin: 0 0 8px 0;
}

.file-details p {
  margin: 0;
  color: #666;
}

.file-search-container {
  padding: 24px;
  height: 80hv;
  overflow-y: auto;
}

.file-search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.search-controls {
  display: flex;
  gap: 12px;
}

.search-bar {
  margin-bottom: 16px;
}

.search-bar input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.filter-tags {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.filter-tag {
  background: #5B6AD0;
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.files-count {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
}

.files-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.file-details {
  flex: 1;
  margin-left: 16px;
}

.file-details h3 {
  margin: 0 0 8px 0;
}

.file-details p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.open-file-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 8px;
}

/* Main Content Area */
.file-search-container {
  margin-left: 0px;
  padding: 24px;
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: column;
}

.main-search-input {
  position: relative;
  width: 80%;
  max-width: 800px;
  margin: 2rem auto;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input-wrapper input {
  width: 100%;
  height: 48px;
  padding: 8px 120px 8px 16px;
  border: 1px solid #dce0e5;
  border-radius: 8px;
  font-size: 16px;
}

.search-input-wrapper input:focus {
  outline: none;
  ring: 2px;
  ring-color: #3498db;
}

.search-button {
  position: absolute;
  right: 8px;
  height: 36px;
  top: 6px;
  padding: 0 16px;
  background-color: #3498db;
  color: white;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
  border: none;
}

.search-button:hover {
  background-color: #2980b9;
}

.file-content {
  padding: 0 24px;
}

/* Rest of your existing CSS styles... */

/* Update filter section inputs to match main search input */
.filter-section input,
.filter-section select,
.react-datepicker__input-container input {
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border: 1px solid #dce0e5;
  border-radius: 8px;
  font-size: 16px;
}

.filter-section input:focus,
.filter-section select:focus,
.react-datepicker__input-container input:focus {
  outline: none;
  ring: 2px;
  ring-color: #3498db;
}

.loading-state,
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  color: #666;
  text-align: center;
}

.loading-state i {
  font-size: 24px;
  margin-bottom: 16px;
  color: #3498db;
}

.file-snippet {
  margin-top: 8px;
  color: #666;
  font-size: 14px;
  background: #f8f9fa;
  padding: 8px;
  border-radius: 4px;
  line-height: 1.4;
}

.file-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.file-size {
  color: #666;
  font-size: 14px;
}

.file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
}

.file-icon i.fa-file-pdf {
  color: #dc3545;
}

.file-icon i.fa-file-word {
  color: #2b579a;
}

.file-icon i.fa-file-excel {
  color: #217346;
}

.file-icon i.fa-file {
  color: #6c757d;
}

/* Main content positioning - single definition */
.dashboard-content {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  padding-bottom: 80px;
}

.content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.file-content {
  overflow-y: auto;
}

.files-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

/* File content area */
.file-content {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  height: calc(100vh - 200px) !important;
  overflow: hidden !important;
}

/* Make the files list scrollable */
.files-list {
  flex: 1 !important;
  overflow-y: auto !important;
  padding-right: 16px !important;
}

/* Keep the header content fixed */
.welcome-text,
.files-count {
  flex-shrink: 0 !important;
}

/* Keep the search input fixed at the top */
.chat-input-container {
  flex-shrink: 0 !important;
  margin-bottom: 16px !important;
}

/* Keep the filter tags fixed below search */
.filter-tags {
  flex-shrink: 0 !important;
  margin-bottom: 16px !important;
}

/* Filter content */
.filter-content {
  height: calc(100% - 60px) !important;
  overflow-y: auto !important;
} 