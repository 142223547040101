/* Base styles */
:root {
    --primary-blue: #0066FF;
    --text-primary: #111827;
    --text-secondary: #4B5563;
    --background-light: #F9FAFB;
    --card-background: #FFFFFF;
    --border-color: #E5E7EB;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: var(--background-light);
    color: var(--text-primary);
  }
  
  /* Dashboard Layout */
  .dashboard-container {
    display: flex;
    min-height: 100vh;
    background-color: white;
  }
  
  .dashboard-content {
    flex: 1;
  }
  
  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 2rem 1.5rem 2rem;
    text-align: left;
  }
  
  main-content {
    max-width: 80%; 
  }
  /* Welcome Header */
  .welcome-text {
    font-size: 32px;
    font-weight: bold;
    color: #2c3338;
    margin-bottom: 2rem;
    text-align: left;
  }
  
  /* Chat Input Section */
  .chat-input-container {
    position: relative;
    margin-bottom: 2rem;
    height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .chat-input {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    padding-right: 100px;
    border: 1px solid #3498db;
    border-radius: 1rem;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .chat-input:focus {
    outline: none;
    ring: 2px;
    ring-color: #3498db;
  }
  
  /* Dashboard home page Ask AI button */
  .chat-button {
    position: absolute;
    right: 2%;
    height: 36px;
    margin: auto;
    top: -6%;
    bottom: 6px;
    width: auto;
    min-width: 90px;
    padding: 0 16px;
    background-color: #3498db;
    color: white;
    border-radius: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: background-color 0.2s;
    border: none;
  }
  
  .chat-button:hover {
    background-color: #2980b9;
  }
  
  .chat-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Font Awesome icon styles */
  .chat-button i {
    font-size: 16px;
    margin-right: 8px;
  }
  
  .chat-button i.fa-spinner {
    animation: spin 1s linear infinite;
  }
  
  .chat-button i.fa-paper-plane {
    margin-right: 8px;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .chat-button span {
    white-space: nowrap;
  }
  
  /* Features Grid */
  .features-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .features-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  /* Navigation */
  .dashboard-nav {
    background: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
  }
  
  .nav-left h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .nav-button {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    background: white;
    color: var(--text-primary);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .nav-button:hover {
    background: var(--background-light);
  }
  
  /* Main Content */
  .welcome-section {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .welcome-section h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--text-primary);
  }
  
  .welcome-section p {
    font-size: 1.1rem;
    color: var(--text-secondary);
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Features Grid */
  .feature-card {
    background: var(--card-background);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .feature-card h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }
  
  .feature-card h2 i {
    color: var(--primary-blue);
  }
  
  .feature-card p {
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
  }
  
  /* Upload Section */
  .upload-section {
    min-height: 120px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
  }
  
  .upload-status {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 6px;
    font-size: 0.9rem;
  }
  
  .upload-status.success {
    background-color: #f0fdf4;
    color: #166534;
    border: 1px solid #dcfce7;
  }
  
  .upload-status.error {
    background-color: #fef2f2;
    color: #991b1b;
    border: 1px solid #fee2e2;
  }
  
  .upload-status.info {
    background-color: #f0f9ff;
    color: #075985;
    border: 1px solid #e0f2fe;
  }
  
  /* Query Section */
  .query-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .query-form {
    position: sticky;
    top: 0;
    background: white;
    padding: 1rem 0;
    z-index: 1;
  }
  
  /* Chat Container */
  .chat-container {
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    height: 400px;
    background: white;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
  
  .chat-messages {
    padding: 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .message {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    position: relative;
  }
  
  .message.user {
    align-self: flex-end;
    
    .message-content {
      background: #0084ff;
      color: white;
      border-radius: 18px 18px 4px 18px;
      padding: 0.75rem 2.5rem 0.75rem 1rem;
    }

    .message-image-container {
      background: #0084ff;
      border-radius: 18px 18px 4px 18px;
      overflow: hidden;

      .message-text {
        color: white;
        padding: 8px 12px;
      }
    }

    .message-file {
      background: #0084ff;
      color: white;
      border-radius: 18px 18px 4px 18px;
      
      .file-preview {
        background: rgba(0, 0, 0, 0.1);
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: white;
        }
      }

      .file-info {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .filename {
          font-weight: 500;
          word-break: break-all;
        }

        .message-text {
          font-size: 0.9rem;
          line-height: 1.4;
          word-break: break-word;
          opacity: 0.9;
        }
      }
    }
  }
  
  .message.ai {
    align-self: flex-start;
    
    .message-content {
      background: #f0f0f0;
      color: #1a1a1a;
      border-radius: 18px 18px 18px 4px;
      padding: 0.75rem 2.5rem 0.75rem 1rem;
    }

    .message-image-container {
      background: #f0f0f0;
      border-radius: 18px 18px 18px 4px;
      overflow: hidden;

      .message-text {
        color: #1a1a1a;
        padding: 8px 12px;
      }
    }

    .message-file {
      background: #f0f0f0;
      color: #1a1a1a;
      border-radius: 18px 18px 18px 4px;
      
      .file-preview {
        background: rgba(0, 0, 0, 0.05);
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: #666;
        }
      }

      .file-info {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .filename {
          font-weight: 500;
          word-break: break-all;
        }

        .message-text {
          font-size: 0.9rem;
          line-height: 1.4;
          word-break: break-word;
          opacity: 0.8;
        }
      }
    }
  }

  .message-content {
    font-size: 0.9rem;
    line-height: 1.4;
    word-break: break-word;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
  }

  .message-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    margin: 0;
  }

  .copy-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    color: inherit;
    opacity: 0.6;
    cursor: pointer;
    padding: 0.25rem;
    z-index: 1;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .copy-button:hover {
    opacity: 1;
  }

  @media (prefers-color-scheme: dark) {
    .message.ai {
      .message-file {
        background: rgba(255, 255, 255, 0.1);

        .file-preview {
          background: rgba(255, 255, 255, 0.05);

          svg {
            color: #999;
          }
        }
      }
    }

    .message.user {
      .message-file {
        background: #0084ff;

        .file-preview {
          background: rgba(0, 0, 0, 0.1);
          
          svg {
            color: white;
          }
        }
      }
    }
  }
  
  /* Input and Button Styles */
  .input-button-wrapper {
    display: flex;
    gap: 1rem;
    margin-bottom: 0;
    padding: 1rem;
    background: white;
  }
  
  .input-button-wrapper input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 1rem;
  }
  
  .input-button-wrapper input:focus {
    outline: none;
    border-color: var(--primary-blue);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
  
  .btn {
    background: var(--primary-blue);
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 100px;
  }
  
  .btn:hover {
    opacity: 0.9;
  }

  .btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .btn .loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 100%;
  }

  .btn .spinner {
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-color: currentColor transparent currentColor transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .btn span {
    white-space: nowrap;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /* Remove duplicate spinner styles */
  .chat-button .spinner {
    width: 16px;
    height: 16px;
  }

  .chat-button .loading-state {
    height: 100%;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .dashboard-content {
      padding: 1rem;
    }
    
    .welcome-section h1 {
      font-size: 2rem;
    }
  }
  
  /* Add these new styles for the copy button */
  .copy-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    color: inherit;
    opacity: 0.6;
    cursor: pointer;
    padding: 0.25rem;
  }
  
  .copy-button:hover {
    opacity: 1;
  }
  
  .copy-button .text-success {
    color: #22c55e;
  }
  
  /* Updated styles for floating left menu */
  .left-menu {
    position: fixed;
    top: calc(57px + 1rem);
    left: 2.5rem;
    width: 48px;
    min-height: 180px;
    background-color: #3498db;
    border-radius: 16px;
    padding: 0.5rem 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 40;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .left-menu .menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .left-menu .menu-item:hover {
    background-color: #2980b9;
    transform: scale(1.05);
  }
  
  .left-menu .menu-item.active {
    background-color: #2980b9;
  }
  
  /* Updated styles for logout button */
  .logout-button {
    background-color: #3498db !important;
    color: white !important;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .logout-button:hover {
    background-color: #2980b9 !important;
  }
  
  /* If the button is inside another element that might be affecting its style */
  button.logout-button,
  a.logout-button {
    color: white !important;
    background-color: #3498db !important;
  }
  
  .chat-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    background: white;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
  }
  
  .chat-sidebar-header {
    padding: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 4px;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  .chat-sidebar .chat-list {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
  }
  
  .chat-sidebar .chat-items {
    margin: 0;
    padding: 0;
  }
  
  .chat-sidebar .chat-item {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }
  
  .templates-scroll {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 2rem;
    margin-top: 2rem;
    overflow-y: auto;
    max-height: calc(100vh - 300px);
  }
  
  .template-card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
  }
  
  .template-card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-4px);
  }
  
  .template-card h3 {
    color: #3498db;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #f0f0f0;
  }
  
  .template-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .template-card li {
    color: #4a5568;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 0.5rem;
    background: #f8fafc;
    font-size: 0.95rem;
    line-height: 1.4;
  }
  
  .template-card li:hover {
    color: #3498db;
    background: #f0f9ff;
    transform: translateX(4px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 1400px) {
    .templates-scroll {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 1024px) {
    .templates-scroll {
      grid-template-columns: 1fr;
      padding: 1rem;
    }
    
    .template-card {
      padding: 1.5rem;
    }
  }
  
  /* Templates Grid */
  .templates-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
  }

  .template-section {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .template-section h2 {
    color: #3498db;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #f0f0f0;
  }

  .template-items {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .template-items button {
    text-align: left;
    padding: 0.75rem 1rem;
    background: #f8fafc;
    border: none;
    border-radius: 0.5rem;
    color: #4a5568;
    font-size: 0.95rem;
    line-height: 1.4;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .template-items button:hover {
    background: #f0f9ff;
    color: #3498db;
    transform: translateX(4px);
  }

  @media (max-width: 1200px) {
    .templates-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    .templates-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    
    .template-section {
      padding: 1rem;
    }
  }

  /* Remove the custom spinner styles since we're using Font Awesome */
  .chat-button .spinner,
  .chat-button .loading-state {
    display: none;
  }

  /* Chat input Ask AI button */
  .query-section .chat-button {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }