/* Mobile Dashboard Base Layout */
.mobile-dashboard {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 57px);
  background-color: #ffffff;
  position: relative;
}

/* Header Styles */
.mobile-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #f0f2f4;
  background: white;
  z-index: 10;
}

.mobile-menu-button {
  background: none;
  border: none;
  padding: 0.5rem;
  color: #111827;
  cursor: pointer;
  margin-right: 1rem;
}

.mobile-title {
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: #111827;
  margin-right: 2.5rem; /* To center the title accounting for the menu button */
}

/* Main Content Area */
.mobile-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Suggestions Grid */
.mobile-suggestions {
  padding: 0.5rem;
}

.suggestions-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
  padding: 0.5rem;
}

.suggestion-card {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.suggestion-card:active {
  background: #f3f4f6;
  transform: scale(0.98);
}

.suggestion-card h3 {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #111827;
}

.suggestion-card p {
  font-size: 0.8rem;
  color: #6b7280;
  line-height: 1.2;
}

/* Input Area */
.mobile-input-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: white;
  border-top: 1px solid #f0f2f4;
}

.mobile-input-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  padding: 0.5rem;
}

.mobile-input {
  flex: 1;
  border: none;
  padding: 0.5rem;
  font-size: 0.9rem;
  background: transparent;
  outline: none;
}

.mobile-input::placeholder {
  color: #9ca3af;
}

.mobile-send-button {
  padding: 0.5rem;
  border-radius: 8px;
  background: #3498db;
  color: white;
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.mobile-send-button:disabled {
  background: #e5e7eb;
}

.mobile-send-button i {
  font-size: 0.9rem;
}

/* Chat Messages */
.mobile-messages {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}

.mobile-message {
  padding: 1rem;
  border-radius: 12px;
  max-width: 85%;
}

.mobile-message.user {
  background: #3498db;
  color: white;
  align-self: flex-end;
}

.mobile-message.assistant {
  background: #f3f4f6;
  color: #111827;
  align-self: flex-start;
}

/* Loading States */
.mobile-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: #6b7280;
}

.mobile-loading i {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Sliding Menu */
.mobile-sliding-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 85%;
  max-width: 320px;
  background: white;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.mobile-sliding-menu.open {
  transform: translateX(0);
}

.mobile-sliding-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #f0f2f4;
}

.mobile-sliding-header h2 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #111827;
}

.mobile-close-button {
  background: none;
  border: none;
  padding: 0.5rem;
  color: #6b7280;
  cursor: pointer;
}

.mobile-chat-list {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.mobile-chat-item {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mobile-chat-item:hover {
  background-color: #f3f4f6;
}

.mobile-chat-item.active {
  background-color: #e5e7eb;
}

.mobile-chat-item .chat-title {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.25rem;
}

.mobile-chat-item .chat-date {
  font-size: 0.8rem;
  color: #6b7280;
}

/* Overlay */
.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.mobile-overlay.open {
  opacity: 1;
  visibility: visible;
}

/* Add this to your existing styles */
.mobile-empty-state {
  padding: 2rem 1rem;
  text-align: center;
  color: #6b7280;
  font-size: 0.9rem;
}

/* Add these new styles */

.mobile-upload-button {
  padding: 0.5rem;
  background: none;
  border: none;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
}

.mobile-upload-button:active {
  background-color: #f7f7f7;
}

.mobile-upload-text {
  font-size: 17px;
  color: #000;
}

.mobile-upload-icon {
  color: #8e8e93;
}

.mobile-upload-options {
  position: fixed;
  bottom: 80px;
  left: 1rem;
  right: 1rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.mobile-upload-options button {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: none;
  border: none;
  border-bottom: 1px solid #f3f4f6;
  color: #111827;
  font-size: 1rem;
  text-align: left;
}

.mobile-upload-options button:last-child {
  border-bottom: none;
}

.mobile-upload-options button:active {
  background-color: #f3f4f6;
}

.mobile-upload-options svg {
  color: #6b7280;
}

/* File Preview Styles */
.mobile-file-previews {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  background: #f9fafb;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.file-preview-item {
  position: relative;
  width: calc(33.333% - 0.5rem);
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  border: 1px solid #e5e7eb;
}

.file-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-preview-document {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  text-align: center;
}

.file-preview-document svg {
  color: #6b7280;
  margin-bottom: 0.25rem;
}

.file-name {
  font-size: 0.7rem;
  color: #111827;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.file-size {
  font-size: 0.6rem;
  color: #6b7280;
}

.remove-file-button {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  z-index: 1;
}

.remove-file-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Update input placeholder when files are selected */
.mobile-input-wrapper.has-files .mobile-input::placeholder {
  color: #9ca3af;
}

/* Add these new styles at the end of the file */
.mobile-upload-menu {
  position: fixed;
  bottom: 70px;
  left: 1rem;
  right: 1rem;
  background: white;
  border-radius: 0.75rem;
  overflow: hidden;
  z-index: 50;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mobile-upload-button {
  width: 100%;
  padding: 1rem 1.5rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.mobile-upload-button:last-child {
  border-bottom: none;
}

.mobile-upload-button:active {
  background-color: #f7f7f7;
}

.mobile-upload-text {
  font-size: 17px;
  color: #000;
}

.mobile-upload-icon {
  color: #8e8e93;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #ffffff;
  padding: 0.5rem;
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  margin: 8px;
}

.plus-button {
  padding: 0.5rem;
  color: #3B82F6;
  background: #EBF5FF;
  border-radius: 12px;
}

.message-input {
  flex-grow: 1;
  border: none;
  background: transparent;
  padding: 8px;
  color: #000;
  font-size: 1rem;
}

.message-input::placeholder {
  color: #8e8e93;
}

.send-button {
  padding: 0.5rem;
  color: #3B82F6;
  background: #EBF5FF;
  border-radius: 12px;
}

/* Mobile chat container */
.mobile-chat-container {
  position: fixed;
  top: 60px; /* Adjust based on your header height */
  bottom: 70px; /* Height of input area */
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  background: white;
}

/* Hide scrollbars but keep functionality */
.mobile-chat-container::-webkit-scrollbar {
  display: none;
}

.mobile-chat-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Keep input area fixed at bottom */
.input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 8px;
  border-top: 1px solid #e5e5e5;
}

/* Chat message styles */
.chat-message {
  padding: 12px 16px;
  margin: 8px;
  max-width: 80%;
  border-radius: 16px;
}

.chat-message.user {
  margin-left: auto;
  background-color: #007AFF;
  color: white;
}

.chat-message.ai {
  margin-right: auto;
  background-color: #F2F2F7;
  color: black;
}