@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset and base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f0f3f5;
}
/* Shared container styles */
.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

/* Shared headings */
h1, h2 {
  text-align: center;
}

h1 {
  color: #2c3e50;
  margin-bottom: 30px;
}

h2 {
  color: #3498db;
  margin-top: 0;
}

/* Card styles for general sections */
.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Button styles for general buttons */
.btn, button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover, button:hover {
  background-color: #2980b9;
}

/* Form styles for shared input fields */
.form-group {
  margin-bottom: 15px;
}

.form-control,
input[type="file"], input[type="text"], input[type="email"], 
input[type="password"], input[type="number"], input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Input groups for labels and inputs */
.input-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group label {
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  background: #fff;
  padding: 0 0.25rem;
  color: #666;
  font-size: 0.9rem;
}

/* Login/Register specific containers */
.login-container, .register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5; /* Light background for consistency */
}

.login-box, .register-box {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

/* Status messages */
.error-message {
  color: #d9534f;
  margin-bottom: 1rem;
}

.success-message {
  color: #5cb85c;
  margin-bottom: 1rem;
}

/* Social login buttons */
.social-login {
  margin-bottom: 1.5rem;
}

.social-login-btn {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.google-login {
  background-color: #db4437;
  color: #fff;
}

.microsoft-login {
  background-color: #0078d4;
  color: #fff;
}

/* Form-specific submit buttons */
.login-btn, .register-btn {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.login-btn:hover, .register-btn:hover {
  background-color: #0056b3;
}

/* Link styling */
.register-link, .login-link {
  color: #007bff;
}

.register-link a, .login-link a {
  color: inherit;
  text-decoration: none;
}

.register-link a:hover, .login-link a:hover {
  text-decoration: underline;
}

/* Flash messages */
.flash-messages {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

/* Error flash message */
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 50%;
  text-align: center;
}

/* Success flash message */
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 50%;
  text-align: center;
}

/* Progress bar styling */
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
  display: none;
}

.progress-bar-fill {
  display: block;
  height: 22px;
  background-color: #3498db;
  border-radius: 3px;
  transition: width 500ms ease-in-out;
}

/* Status for uploads and answers */
#uploadStatus, #answer {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #e8f4fd;
  border: 1px solid #3498db;
}

.onboarding-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

.onboarding-content {
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.info-section {
  margin-bottom: 30px;
  text-align: center;
}

.email-box {
  background: #f0f2f4;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.email-box code {
  background: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1.1em;
}

.features-section {
  margin: 30px 0;
}

.feature-item {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: flex-start;
}

.feature-item i {
  font-size: 24px;
  color: #1980e6;
  margin-top: 4px;
}

.feature-item h4 {
  margin: 0 0 8px 0;
  color: #111418;
}

.feature-item p {
  margin: 0;
  color: #637588;
}

.continue-button {
  width: 100%;
  padding: 12px;
  background-color: #1980e6;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.continue-button:hover {
  background-color: #1666b8;
}

/* Mobile menu styles */
.mobile-menu-enter {
  transform: translateY(-100%);
}

.mobile-menu-enter-active {
  transform: translateY(0);
  transition: transform 200ms ease-in-out;
}

.mobile-menu-exit {
  transform: translateY(0);
}

.mobile-menu-exit-active {
  transform: translateY(-100%);
  transition: transform 200ms ease-in-out;
}

/* Responsive text */
.text-responsive {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
}

/* Responsive spacing */
.section-padding {
  padding: clamp(2rem, 5vw, 4rem) 0;
}

/* Add these new utility classes */
.page-container {
  width: 100%;
  background: white;
  min-height: screen;
}

.content-wrapper {
  width: 100%;
  padding: 2rem 1.5rem;
}

.section-header {
  margin-bottom: 3rem;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

/* Responsive breakpoints */
@media (min-width: 768px) {
  .content-wrapper {
    padding: 2rem 2.5rem;
  }
  
  .grid-layout {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1280px) {
  .content-wrapper {
    padding: 2rem 3rem;
  }
  
  .grid-layout {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Card styles */
.use-case-card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
}

.example-box {
  background-color: #f0f2f4;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-top: auto;
}

/* Add these shared header styles after your existing styles */

/* Common header styles */
.view-header {
  padding: 24px;
  border-bottom: 1px solid #eee;
  margin-bottom: 24px;
}

/* Title styling - consistent across all views */
.view-title {
  font-size: 28px;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 12px 0;
}

/* Description styling */
.view-description {
  font-size: 16px;
  color: #4a5568;
  margin: 0 0 8px 0;
  line-height: 1.5;
}

/* Date styling */
.view-date {
  font-size: 14px;
  color: #718096;
  margin: 0;
}

.facebook-login-button button {
  transition: background-color 0.3s ease !important;
}

.facebook-login-button button:hover {
  background-color: #1464d6 !important;
}

