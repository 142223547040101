.chat-sidebar {
  position: fixed;
  right: 0;
  top: calc(57px + 1rem);
  bottom: 80px;
  width: 320px;
  background: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.chat-sidebar.open {
  transform: translateX(0);
}

.chat-sidebar-header {
  padding: 16px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-list {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.chat-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-item {
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.chat-item:hover {
  background-color: #f5f5f5;
}

.chat-item-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chat-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.chat-item-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-date {
  font-size: 12px;
  color: #666;
}

.delete-chat-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  font-size: 12px;
}

.delete-chat-button:hover {
  color: #ff4444;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #666;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #333;
}

/* Loading and error states */
.loading-state,
.error-state {
  text-align: center;
  padding: 20px;
  color: #718096;
}

.error-state {
  color: #e53e3e;
}

/* Empty state */
.empty-state {
  text-align: center;
  padding: 40px 20px;
  color: #718096;
}
 