.left-menu {
  position: fixed;
  top: calc(57px + 1rem);
  left: 2.5rem;
  width: 48px;
  min-height: 180px;
  background-color: #3498db;
  border-radius: 16px;
  padding: 0.5rem 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 40;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: none;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

/* Remove active state */
.menu-item.active {
  background-color: transparent;
}

.menu-item svg {
  width: 24px;
  height: 24px;
} 